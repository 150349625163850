import {useEffect} from 'react';
import API from '../API';
import {useDispatch} from 'react-redux';
import Ad from '../layout/Ad';
import Video from '../components/Video';
import Head from 'next/head';
import Error from '../components/Error';
import {getBannerURL, getProfilePictureURL, UserBadge} from '../util/userUtil';
import Link from 'next/link';
import {I18nText} from '../i18n/I18nText';
import {useApplyAdsenseWhenPageLoaded} from '../util/applyAdsenseWhenPageLoaded';

export default function Index({data, error}) {
    const dispatch = useDispatch();
    useApplyAdsenseWhenPageLoaded()

    useEffect(() => {
        dispatch({type: 'navbar:setActiveCategory', val: 'home'});
    }, []);

    if (error) {
        return <Error error={error}/>;
    }

    return <div className="p-3 max-w-1300px m-auto">
        <Head>
            <title>CornHub</title>
        </Head>

        <Ad orientation="horizontal" page="frontpage" position="top"/>

        {data.hot ? <div>
            <h1 className="text-2xl font-bold mb-3"><I18nText id="homepage.hot"/></h1>
            <div className="grid gap-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
                {
                    data.hot.map((video) => <Video video={video} key={video.id}/>)
                }
                <div className="mt-1 overflow-hidden max-h-[100%]">
                    <div className="min-w-[250px]">
                        <ins className="adsbygoogle"
                             style={{display: 'block'}}
                             data-ad-format="fluid"
                             data-ad-layout-key="+1r+s5-c-5d+bd"
                             data-ad-client="ca-pub-1284128935095137"
                             data-ad-slot="4003912274"></ins>
                    </div>
                </div>
            </div>
        </div> : null}

        {data.featured_models ? <div className="mt-5 pt-5 border-t-1 border-primary border-dashed border-opacity-25">
            <h1 className="text-2xl font-bold mb-3"><I18nText id="homepage.featured"/></h1>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 w-full">
                {data.featured_models.map((model) => <Link
                    className="bg-cover bg-center bg-no-repeat thumbnail-aspect-ratio flex"
                    href={`/model/${model.username}`} key={model.id} style={{
                    backgroundImage: `url(${getBannerURL(model)})`
                }}>
                    <div className="w-1/4 ml-3 mt-auto aspect-square bg-no-repeat bg-contain" style={{
                        backgroundImage: `url(${getProfilePictureURL(model)})`,
                        backgroundPosition: '50% 50%'
                    }}/>
                    <h1 className="text-2xl ml-2 font-bold mt-auto">{model.display_name ?? model.username}</h1>
                    <UserBadge user={model} className="inline ml-1 mt-auto mb-2"/>
                </Link>)}
            </div>
        </div> : null}

        {data.new_videos ? <div className="mt-5 pt-5 border-t-1 border-primary border-dashed border-opacity-25">
            <h1 className="text-2xl font-bold mb-3"><I18nText id="homepage.newest"/></h1>
            <div className="grid gap-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
                {
                    data.new_videos.map((video) => <Video video={video} key={video.id} showTimeAgo={true}/>)
                }
            </div>
        </div> : null}

        <Ad orientation="horizontal" page="frontpage" position="bottom"/>
    </div>;
}

Index.getInitialProps = async ({req, res = {}}) => {
    const {data, errored} = await API.get('/recommendation/frontpage', {req});

    if (errored) {
        res.statusCode = 503;
        return {error: {status: 503}};
    }

    return {data};
}
